import React from 'react';
import "./Footer.css";


class Footer extends React.Component {

  render() {

    return (
      <div id="footer">
        Biblebase
      </div>
    );
  }
}

export default Footer;
